import {Controller} from "@hotwired/stimulus"
import {buildHeaders, buildRequest, buildUrl} from "../helpers/build_request";
import {sendTurboStreamRequest} from "../helpers/turbo_stream_helper";

// Connects to data-controller="warehouse-direct-transfer-validator"
export default class extends Controller {
  static values = {
    turboUrl: String
  }

  static targets = ['quantity'];

  connect() {
    super.connect();
  }

  validateStocksExpireDate(event) {
    const parsedData = this.parseDataset();
    const markedStocks = this.findWrongTransfers(parsedData);
    const anyWrong = markedStocks.some(item => item.wrong_transfer === true);
    if (anyWrong) {
      event.preventDefault();
      const requestBody = JSON.stringify({stocks: markedStocks});
      const url = buildUrl(this.turboUrlValue, {});
      const turboHeaders = buildHeaders({
        "Content-Type": "application/json"
      });
      const request = new Request(url, {method: "POST", headers: turboHeaders, body: requestBody});
      sendTurboStreamRequest(request);
    }
  }

  parseDataset() {
    return Array.from(this.quantityTargets).map((el) => {
      return {
        stock_card_id: el.dataset.stockCardId,
        lot: el.dataset.warehouseStockLot,
        expire_date: el.dataset.warehouseStockExpireDate,
        stock_card_identifier: el.dataset.stockCardIdentifier,
        value: el.value,
        wrong_transfer: false
      };
    });
  }

  parseExpireDate(str) {
    if (!str) return null;
    const cleanedDate = str.replace(/"/g, '');
    return new Date(cleanedDate);
  }

  groupByStockCard(parsedData) {
    return parsedData.reduce((acc, item) => {
      const key = item.stock_card_id;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});
  }

  findWrongTransfers(parsedData) {
    const groupedData = this.groupByStockCard(parsedData);

    Object.keys(groupedData).forEach((stockCardId) => {
      const group = groupedData[stockCardId];

      group.sort((a, b) => {
        return this.parseExpireDate(a.expire_date) - this.parseExpireDate(b.expire_date);
      });

      const earliest = group[0];
      const earliestIsEmpty = !earliest.value || earliest.value.trim() === "";

      if (earliestIsEmpty) {
        const anyLaterHasValue = group.slice(1).some((item) => {
          return item.value && item.value.trim() !== "";
        });

        if (anyLaterHasValue) {
          earliest.wrong_transfer = true;
        }
      }
    });

    return parsedData;
  }

  submit() {
    const transferForm = document.getElementById("direct_transfer_form");
    transferForm.requestSubmit();
  }
};
