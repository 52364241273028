import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";
import { sendTurboStreamRequest } from "../helpers/turbo_stream_helper"
import { buildHeaders, buildRequest, buildUrl } from "../helpers/build_request"

export default class extends Controller {
  static targets = [
    "supplierSelect",
    "trainingInstitution",
    "selectedUsers",
    "row"
  ];

  connect() {
    setTimeout(() => {
      this.initializeSelect();
      this.checkAndSetTrainingInstitution();
    });
  }
  
  initializeSelect() {
    const selectOptions = {
      sortField: {
        field: "text",
        direction: "asc",
      },
      placeholder: this.supplierSelectTarget.dataset.educationPlaceholderValue,
      noResult: this.supplierSelectTarget.dataset.educationNoResultValue,
      create: (input) => {
        this.trainingInstitutionTarget.value = input;
        this.supplierSelectTarget.setAttribute("disabled", "true");
        return {
          value: input,
          text: input,
        };
      },
      onChange: (value) => {
        if (!isNaN(value) && value.trim() !== "") {
          this.trainingInstitutionTarget.value = "";
        } else {
          this.supplierSelectTarget.removeAttribute("disabled");
        }
      },
    };

    this._select = new TomSelect(this.supplierSelectTarget, selectOptions);
  }

  checkAndSetTrainingInstitution() {
    const trainingInstitutionValue = this.trainingInstitutionTarget.value;
    if (
      trainingInstitutionValue &&
      !this.optionExists(trainingInstitutionValue)
    ) {
      this.addCustomOptionIfNeeded(trainingInstitutionValue);
      this._select.setValue(trainingInstitutionValue);
    }
  }

  addCustomOptionIfNeeded(trainingInstitutionValue) {
    if (
      trainingInstitutionValue &&
      !this.optionExists(trainingInstitutionValue)
    ) {
      this._select.addOption({
        value: trainingInstitutionValue,
        text: trainingInstitutionValue,
      });
      this._select.addItem(trainingInstitutionValue, true);
    }
  }

  optionExists(value) {
    return this._select.options[value] !== undefined;
  }

  toggleInput(event) {
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute(
      "data-education-target-id"
    );
    const input = document.getElementById(targetId);
    if (input) {
      input.disabled = !input.disabled;
    }
  }

  toggleAttendantInputs(event) {
    event.preventDefault();
    const formWrapper = event.target.closest(".nested-form-wrapper");

    const inputs = formWrapper.querySelectorAll("input, select, textarea");

    inputs.forEach((input) => {
      if (input.tagName.toLowerCase() === "select") {
        // if input is tomselect
        const tomSelectInstance = input.tomselect;
        if (tomSelectInstance) {
          if (input.disabled) {
            tomSelectInstance.enable();
            tomSelectInstance.unlock();
          } else {
            tomSelectInstance.disable();
            tomSelectInstance.lock();
          }
        }
      } else {
        // html inputs
        input.disabled = !input.disabled;
      }
    });
  }

  turboRequestForFetchUsers(event) {
    event.preventDefault();
  
    const selectedUsers = new Set(
      Array.from(document.querySelectorAll('input[data-education-target="userId"]:checked'))
        .map(checkbox => checkbox.value)
    );
  
    const unselectedUsers = new Set(
      Array.from(document.querySelectorAll('input[data-education-target="userId"]:not(:checked)')) 
        .map(checkbox => checkbox.value)
    );
  
    this.rowTargets.forEach(row => {
      const userInput = row.querySelector('input[name*="[user_id]"]');
      const destroyInput = row.querySelector('input[name*="[_destroy]"]');
  
      if (userInput && destroyInput) {
        if (unselectedUsers.has(userInput.value)) {
          row.classList.add("hidden");
          destroyInput.value = "1"; 
        } else if (selectedUsers.has(userInput.value)) {
          row.classList.remove("hidden");
          destroyInput.value = "0"; 
        }
      }
    });
  
    const existingUserIds = new Set(
      Array.from(document.querySelectorAll('input[name*="[user_id]"]'))
        .map(input => input.value)
    );
  
    const newUsers = [...selectedUsers].filter(userId => !existingUserIds.has(userId));
  
    if (newUsers.length > 0) {
      const turboParams = JSON.parse(event.target.dataset.turboParams);
      const params = { ...turboParams, "user_ids[]": newUsers };
      delete params.url;
  
      const url = buildUrl(turboParams.url, params);
      const request = buildRequest(url, buildHeaders());
      sendTurboStreamRequest(request);
    }
  }
}
