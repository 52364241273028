Turbo.StreamActions.update_multiple_select_options = function () {
  this.targetElements.forEach((target) => {
    target.tomselect.clear();
    target.tomselect.clearOptions();
    target.replaceChildren(...this.templateContent.children);
    target.tomselect.refreshOptions();
    target.tomselect.sync();
  
    const selectedValues = Array.from(this.templateContent.children)
      .filter(option => option.selected)
      .map(option => option.value);

    if (selectedValues.length) {
      target.tomselect.setValue(selectedValues);
    } else {
      target.tomselect.setValue("", true);
      target.value = null;
    }
  });
};
  