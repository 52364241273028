import {Controller} from "@hotwired/stimulus"
import {sendTurboStreamRequest} from "../helpers/turbo_stream_helper"
import {buildHeaders, buildRequest, buildUrl} from "../helpers/build_request"

// Connects to data-controller="device-card"
export default class extends Controller {
  static targets = [
    "brandIdInput",
    "deviceModelIdInput",
    "hourlyCapacityValue",
    'lab',
    'consumptionPoint',
    'labGroup',
    'testGroup',
    'supplier',
  ]

  connect() {
    super.connect();
    setTimeout(() => {
      if (this.deviceModelIdInputTarget.tomselect && this.brandIdInputTarget.tomselect.getValue() == '') {
        this.deviceModelIdInputTarget.tomselect.disable();
      }

      this.labTarget.dispatchEvent(new Event('change'));
      this.testGroupTarget.dispatchEvent(new Event('change'));
      this.supplierTarget.dispatchEvent(new Event('change'));

      this.disableLabAndConsumptionSelectOfExistingCard();
    }, 0);
  }

  disableLabAndConsumptionSelectOfExistingCard() {
    if(!JSON.parse(this.labTarget.dataset.newRecord))
    {
      this.labTarget.tomselect.wrapper.classList.add('unselectable');
      this.consumptionPointTarget.tomselect.wrapper.classList.add('unselectable');
    }
  }

  getBrandDeviceModels() {
    var selectedBrandId = this.brandIdInputTarget.tomselect.getValue();
    var deviceModelInput = this.deviceModelIdInputTarget.tomselect;
    if (selectedBrandId == '') {
      deviceModelInput.clear();
      deviceModelInput.clearOptions();
      deviceModelInput.disable();
    } else {
      deviceModelInput.enable();
      fetch("/brand_device_models?brand_id=" + selectedBrandId)
          .then(response => response.json())
          .then(data => this.updateDeviceModelInput(deviceModelInput, data))
    }
  }

  updateDeviceModelInput(deviceModelInput, deviceModels) {
    deviceModelInput.clear();
    deviceModelInput.clearOptions();
    deviceModelInput.addOptions(deviceModels);
    deviceModelInput.refreshOptions();
    deviceModelInput.refreshItems();
    deviceModelInput.sync();
  }

  getDeviceModelHourlyCapacity() {
    var deviceModelInput = this.deviceModelIdInputTarget.tomselect.getValue();
    if (deviceModelInput) {
      fetch("/device_model_hourly_capacity?model_id=" + deviceModelInput)
          .then(response => response.json())
          .then(data => this.updateDeviceModelHourlyCapacity(data));
    }
  }

  updateDeviceModelHourlyCapacity(data) {
    this.hourlyCapacityValueTarget.value = data["hourly_capacity"];
  }

  updateConsumptionPointState() {
    let consumptionPoint = this.consumptionPointTarget.tomselect;
    if (this.labTarget.value === '') {
      consumptionPoint.disable();
    }
    else {
      consumptionPoint.enable();
    }
  }

  updateStateOfLabGroup() {
    const labGroup = this.labGroupTarget.tomselect;
    if (this.testGroupTarget.value === '') {
      labGroup.enable();
    }
    else {
      labGroup.disable();
    }
  }

  turboStreamRequestForLabGroups(event) {
    const selectBox = event.target
    const turboParams = JSON.parse(selectBox.dataset.turboParams);
    const params = { id: selectBox.value };
    const url = buildUrl(turboParams.url, params);
    const request = buildRequest(url, buildHeaders());
    sendTurboStreamRequest(request);
  }
}
